
@import "./toolbar.vars.less";
@import "./mixin.buttons.plone.less";
@import "./mixin.prefixes.plone.less";
@import "./mixin.tabfocus.plone.less";
@import "./mixin.borderradius.plone.less";
@import "./mixin.clearfix.plone.less";
@import "./portlets.less";
@import "./dropdowns.less";
@import "./pagination.less";
@import "./variables.less";
// @import "./main.less";

@import "./buttons.less";



.section-anzeigen {
  #portal-header #portal-anontools {
    position: static;
    left: unset!important;
    right: 1em!important;
  }
}

#main-container {
    margin-top: 0em!important;
    margin-bottom: 3!important;
    background: white;
}

#content {
    margin-top: 1em!important;
    margin-bottom: 1em!important;
    background: white;
    border: 1px solid #ddd;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.template-login_form,
.template-login_success {
  #main-container {
    background: transparent!important;
    #content {
      // border: 0!important;
      // background: transparent!important;
      padding-top: 2em!important;
      padding-bottom: 2em!important;
    }
  }
}
.template-logged_out {
  #main-container {
    background: transparent!important;
    #content {
      // border: 0!important;
      // background: transparent!important;
      padding-top: 1em!important;
      padding-bottom: 2em!important;
    }
  }
}


#content-core {
  position: relative;
}
h1.documentFirstHeading {
  padding-top: 0.25em;
  margin-bottom: 1em;
}


.contentToolBar {
  // float: right;
  // border-bottom: @plone-border-base @plone-gray-lighter;
  .clearfix();
  a {
    margin-top: 4em;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 0;
  }
}

.contentAddBar {
  float: left;
  .action {
    padding: 0.1em 0.35em 0.2em 0.35em!important;
  }
}

.addAd {
}
.addAdParent {
}
.publishAd {
}

.leadImageContainer {
  .leadImage {
    float: right;
  }
}

.answerButton {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.portaltype-ad {
  h2 {
    font-size: @plone-font-size-h3;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  .documentByLine {
    margin-top: -1.25em;
  }
  .plone-modal .plone-modal-content {
      max-width: 500px;
  }
  #parent-fieldname-text {
    margin-bottom: 1.5em;
  }
  .answerButton {
    display: none;
  }
}

.template-ads_listing {
  h1.documentFirstHeading {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  .documentByLine {
    margin-bottom: 1em;
  }
  #parent-fieldname-text {
    margin-bottom: 2em;
  }
  .answerButton {
    margin-bottom: 0;
  }
  .itemWrapper {
    position: relative;
    margin-bottom: 5em;
    .contentToolBar {
      right: 0;
      bottom: -2em;
    }
    .item {
      position: relative;
      padding: 1em;
      background: #eee;
      .clearfix();
      .headline {
      margin-top: 0;
      font-size: @plone-font-size-h3;
        a {
          border: 0;
        }
      }
    }
  }
  .contentToolBar {
    position: absolute;
    margin-top: 0;
    right: 1em;
    .action {
    padding: 0em 0.25em 0.1em 0.25em!important;
    }
  }
}

.formControls {
  background: none!important;
  box-shadow: none!important;
  // padding-left: 0!important;
  margin-top: 2em!important;
}

#commenting {
  margin-top: 2em;
  legend {
    font-size: @plone-font-size-h4;
    font-weight: 500;
    padding-bottom: 0.25em!important;
    margin-bottom: 0.5em!important;
    margin-top: 0em!important;
  }
  #formfield-form-widgets-comment-text label {
    display: none;
  }
  .formControls {
    border: none;
    background: none;
    box-shadow: none;
    padding-left: 0;
    padding-top: 0!important;
    margin-top: 0!important;
    margin-bottom: 0.5em!important;
  }
  .field.error div.error::before {
    content: "";
    width: 0;
    margin-right: 0;
  }
}

.portlet .portletContent > ul > li a::before {
    content: "•";
    left: 0 !important;
}
.portlet .portletContent > ul > li a {
    padding: 10px 15px 26px 15px!important;
}

body#visual-portal-wrapper.pat-plone .outer-wrapper [class*="contenttype-"]::before {
    // width: 10px!important;
    margin-right: 0!important;
}

.documentByLine {
    font-size: 95%!important;
}

.anzeigen-buttons {
  text-align: center;
  display: none;
  margin-top: 2em;
  margin-bottom: 3em;
}
.userrole-anonymous .anzeigen-buttons {
  display: block;
}
#portal-membertools-wrapper {
  float: right;
  text-align: right;
  margin-top: 0.5em;
  #portal-membertools {
    .dropdown-menu {
      left: unset;
      right: 0;
    }
  }
}

#global_statusmessage {
  .portalMessage {
    margin-top: 2em;
    margin-bottom: 0;
  }
}
#above-content-wrapper {
  background: white;
  padding-top: 1em;
  padding-bottom: 0em;
  .clearfix();
}

#form-widgets-mail_me > span {
  display: none;
}
.template-mail_password_form,
.template-mail_password_response,
.template-passwordreset {
  h1.documentFirstHeading {
    padding-top: 0.75em!important;
  }
}
.template-mail_password_form,
.template-mail_password_response,
.template-passwordreset {
  .formControls {
    border: none;
    background: none;
    box-shadow: none;
    padding-left: 0;
    padding-top: 0!important;
    margin-top: 0!important;
    margin-bottom: 2em!important;
  }
}
#contentview-user_data-personal-preferences,
#formfield-form-widgets-fullname label .formHelp,
#formfield-form-widgets-email label .formHelp {
  display: none;
}


// ad placement
.below_content_adspace {
  margin-top: 2em!important;
  margin-bottom: 3em!important;
}
.portletEmbed.portlet-embed-ads-side {
  margin-top: 1em;
  .side_adspace {
    margin-bottom: 2em;
  }
}
.portletWorkflowReview {
  margin-top: 1em;
}
@isPlone:false;
@isPlone: false;